export function cloneObject<T>(object: T, deepClone: boolean = true): T {
  if (deepClone) {
    return JSON.parse(JSON.stringify(object));
  }
  if (object instanceof Array) {
    const array = object as T[];
    return [...array] as any; // fix per gli array
  }
  return Object.assign({}, object);
}

export function padLeft(num: number | string, size: number, symbol: string = '0'): string {
  let s = num + '';
  while (s.length < size) {
    s = symbol + s;
  }
  return s;
}

export function padRight(num: number | string, size: number, symbol: string = '0'): string {
  let s = num + '';
  while (s.length < size) {
    s = s + symbol;
  }
  return s;
}

export function createDateFromRieskoDate(rieskoDate: { /*dis?: string, ref?: string,*/ cod?: string }): Date {
  if (!rieskoDate.cod || rieskoDate.cod.length < 8) {
    return new Date();
  }

  // const date = new Date(rieskoDate.ref);
  const date = new Date();
  date.setUTCFullYear(parseInt(rieskoDate.cod.substring(0, 4), 10),
    parseInt(rieskoDate.cod.substring(4, 6), 10) - 1,
    parseInt(rieskoDate.cod.substring(6, 8), 10)
  );
  return date;
}

export function createRieskoDateFromDate(date: Date, currentLanguage: string): { dis: string, cod: string, ref: number } {
  let dis: string;
  switch (currentLanguage) {
    default:
    case 'it':
      dis = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      break;
    case 'en':
      dis = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }
  const cod = padLeft(date.getFullYear(), 4) + padLeft(date.getMonth() + 1, 2) + padLeft(date.getDate(), 2) + '000000';
  // const ref = date.getTime();
  const ref = cod;
  const result = {dis, cod, ref: parseInt(ref, 10)};
  console.log({result});
  return result;

}

export function getDateInputFormat(currentLanguage: string): string {
  switch (currentLanguage) {
    default:
    case 'it':
      return 'DD/MM/YYYY';
    case 'en':
      return 'MM/DD/YYYY';
  }
}

export function formatNumberIta(value: number): string {
  return value.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function formatNumberEng(value: number): string {
  return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatNumberLanguage(value: number, language: string) {
  if (language.toLowerCase().startsWith('it')) {
    return formatNumberIta(value);
  }
  return formatNumberEng(value);
}

/**
 * Questa funzione genera un identificativo univoco.
 * @returns un identificativo univoco in formao UUIDv4.
 */
export function uuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // tslint:disable-next-line:no-bitwise
    const r = Math.random() * 16 | 0;
    // tslint:disable-next-line:no-bitwise
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


/**
 * Questa interfaccia definisce un oggetto che ha una proprietà __uuid.
 * Questa proprietà è un identificativo univoco per l'oggetto nella UI.
 */
export interface Uuid {
  __uuid: string;
}

/**
 * Questa funzione inizializza la proprietà __uuid di tutti gli oggetti nell'albero.
 * È utile per l'UI per tenere traccia degli oggetti.
 */
export function initUi_UUID(item: unknown) {
  // if item has __uuid property, initialize it:
  if (item && typeof item === 'object' && '__uuid' in item) {
    (item as Uuid).__uuid = uuid();
  }
  // check if item is an array and initialize all its elements:
  if (item && Array.isArray(item)) {
    (item as Uuid[]).forEach((element) => initUi_UUID(element));
  }
  // foreach property of item, initialize it:
  if (item && typeof item === 'object') {
    Object.keys(item).forEach((key) => initUi_UUID((item as any)[key]));
  }
}

